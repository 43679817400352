import {Injectable} from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable()
export class CrReqRes {


    constructor() {
    }

    CIPHERS: any = {
        AES_128: 'aes128', // requires 16 byte key
        AES_128_CBC: 'aes-128-cbc', // requires 16 byte key
        AES_192: 'aes192', // requires 24 byte key
        AES_256: 'aes256', // requires 32 byte key
    };

    forward(alg: any, gate: any, iv: any, text: any) {
        const words = CryptoJS.enc.Utf8.parse(text);
        return CryptoJS.AES.encrypt(words, gate, {iv});
    }

    rewind(alg: any, gate: any, iv: any, text: any) {
        const words = CryptoJS.AES.decrypt(text, gate, {iv});
        return CryptoJS.enc.Utf8.stringify(words);
    }

    getGateAndIV(gate: any, rawIV: any) {
        if (!gate || !rawIV) {
            return null;
        }
        const ivBuffer = CryptoJS.enc.Utf8.parse(rawIV);
        const keyBuffer = CryptoJS.enc.Utf8.parse(gate);

        return {
            iv: ivBuffer,
            gate: keyBuffer,
        };
    }
}

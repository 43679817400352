import {HttpResponse} from '@angular/common/http';


/**
 * Class that defines the starndard structure of an Error Response
 */
export class ErrorResponse extends HttpResponse<string> {

    public static CLOUD_ERROR: number = -1;
    public static SIMPLE_STORAGE_ERROR: number = -2;
    public static STORAGE_ERROR: number = -3;
    public static ANALYTICS_ERROR: number = -4;

    constructor(message: string, statusCode: number) {

        super({
            body: JSON.stringify({
                    message
                }
            ),
            status: statusCode
        });

    }

    public static getMessage(error: ErrorResponse): string {
        if (error) {
            try {
                // let responseBody: any = error.json();
                // TODO: Cambiar como obtenemos el mensaje
                const responseBody: any = 'error';
                return responseBody.message;
            } catch (e) {
                return 'Error';
            }
        } else {
            return 'error';
        }
    }

}

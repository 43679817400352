<ion-app>
  <ion-split-pane class="main-split-pane" [disabled]="!MenuVisible" contentId="main-content">
    <ion-menu *ngIf="!this.isIndividual" id="menu-sidebar" class="main-sidebar" contentId="main-content" side="start">
      <ion-header class="text-center">
        <ion-img class="fd logo inline" src="assets/images/forza-delivery-logo-4-darkbg.png" [routerLink]="''"> </ion-img>
      </ion-header>

      <ion-content (mouseout)="authService.refreshExpirationLogin()">
        <ion-list>
          <div *ngFor="let p1 of authService.appPages">
            <ion-menu-toggle *ngIf="!p1.SubModule || p1?.SubModule?.length <= 0" auto-hide="false">
              <ion-item
                lines="none"
                (click)="backCreateGuide(p1.Module)"
                [routerLink]="p1.Path"
                routerDirection="root"
              >
                <ion-icon name="chevron-forward"></ion-icon>
                {{ p1.Module }}
              </ion-item>
            </ion-menu-toggle>
            <ion-item *ngIf="p1.SubModule && p1?.SubModule?.length >= 1" lines="none" (click)="showSubMenu(p1)" [routerLink]="">
              <ion-icon name="chevron-forward"></ion-icon>
              {{ p1.Module }}
            </ion-item>
            <ion-list *ngIf="p1.show">
              <ion-menu-toggle auto-hide="false">
                <ion-item
                  style="margin-left: 20px"
                  *ngFor="let subP of p1.SubModule"
                  lines="none"
                  [routerLink]="subP.Path"
                  routerDirection="root"
                >
                  <ion-icon name="chevron-forward"></ion-icon>
                  {{ subP.Module }}
                </ion-item>
              </ion-menu-toggle>
            </ion-list>
          </div>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-menu-toggle auto-hide="false">
          <ion-toolbar>
            <ion-item (click)="authService.setLogout()" class="cursor-pointer text-hover-primary"> Cerrar Sesión </ion-item>
          </ion-toolbar>
        </ion-menu-toggle>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content" main></ion-router-outlet>
  </ion-split-pane>
</ion-app>

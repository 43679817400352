import { Platform } from "@ionic/angular";
import { LocalSimpleStorage } from "./local-simple-storage/local-simple-storage";
import { LocalStorageData } from "./local-storage-data/local-storage-data";
import { CrReqRes } from "../../domain/utility/crReqRes";
import { environment } from "src/environments/environment";

export let dataSourceSecureFactory = (platform: Platform, crReqRes: CrReqRes) => {
  if (environment.production) {
    return new LocalStorageData(crReqRes);
  } else {
    return new LocalSimpleStorage();
  }
};

import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Contract } from "src/data/disk-data-source/contract";
import { SecureSimpleStorage } from "src/data/disk-data-source/secure-simple-storage";

@Injectable({
  providedIn: "root",
})
export class AuthGuardRedirect implements CanActivate {
  constructor(private authService: AuthService, public router: Router, private secureStorage: SecureSimpleStorage) {}

  canActivate(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
          this.secureStorage.save('Afiliados', '1').subscribe();
          window.location.href = environment.rediretPath;
          observer.next(false);
          observer.complete();
      });
}}

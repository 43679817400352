import {SecureSimpleStorage} from '../secure-simple-storage';
import {Contract} from '../contract';
import {Observable, Observer} from 'rxjs';
import {ErrorResponse} from '../../../domain/entities/error-response';

export class LocalSimpleStorage extends SecureSimpleStorage {

    constructor() {
        super();
    }

    /**
     * Save any value with the localStorage library of Vanilla JS
     * @param key: Contract, a key from the Contract enum
     * @param value: any
     * @returns any: Observable, with the ok resolution or the error
     */
    save(key: Contract, value: any): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            try {
                localStorage.setItem(String(key), value);
                // AppLog.log(`SET ` + key + ' ' + value);
                observer.next(true);
                observer.complete();
            } catch (e) {
                // AppLog.log(e);
                observer.error(new ErrorResponse('Error', ErrorResponse.SIMPLE_STORAGE_ERROR));
            }
        });
    }

    /**
     * Get any value with the localStorage library of Vanilla JS
     * @param key: Contract, a key from the Contract enum
     * @returns any: Observable<any>, with the ok resolution containing the value extracted from disk or the error
     */
    get(key: Contract): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            try {
                const data: string = localStorage.getItem(String(key));
                // AppLog.log(`GET ` + data);
                if (data) {
                    observer.next(data);
                } else {
                    observer.error('null');
                }
                observer.complete();
            } catch (e) {
                // AppLog.log(e);
                observer.error(new ErrorResponse('Error', ErrorResponse.SIMPLE_STORAGE_ERROR));
            }
        });
    }

    /**
     * Removes specific key or all the keys with the localStorage library of Vanilla JS
     * @param keys: Contract, a key from the Contract enum
     * @returns any: Observable, with the ok resolution or the error
     */
    remove(keys?: Array<Contract>): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            try {
                if (!!keys) {
                    keys.forEach((key: string) => {
                        localStorage.removeItem(key);
                    });
                } else {
                    localStorage.clear();
                }
                observer.next(true);
            } catch (e) {
                observer.error(e);
            }
        });
    }
}

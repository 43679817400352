import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate} from "@angular/router";
import { Observable, Observer } from "rxjs";
import { Contract } from "src/data/disk-data-source/contract";
import { SecureSimpleStorage } from "src/data/disk-data-source/secure-simple-storage";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthGuardRedirect implements CanActivate {
  constructor(private secureStorage: SecureSimpleStorage) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
          const url = route.data.url;
          this.secureStorage.save(Contract.routeActive, url).subscribe();
          window.location.href = environment.rediretPath;
          observer.next(false);
          observer.complete();
      });
  }
}
import Swal from "sweetalert2";
import { Injectable } from "@angular/core";

@Injectable()
export class SweetAlert {
  constructor() {}

  errorAlertCritico(titulo: string, mensaje: string, event?: any, icon?: any) {
    Swal.fire({
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      //text: "<b>" + mensaje + "</b>",
      icon: icon ? icon : "error",
      iconColor: "red",
      confirmButtonColor: "#FF5100",
      confirmButtonText: "Aceptar",
      backdrop: false,
      allowOutsideClick: true,
      //position: 'center',
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      customClass: { container: "swal-wide", confirmButton: "colorTexto" },
      onClose: () => {
        if (event) event();
      },
    });
  }

  successAlertCritico(titulo: string, mensaje: string, event?: any) {
    Swal.fire({
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      //text: "<b>" + mensaje + "</b>",
      icon: "success",
      confirmButtonColor: "#FF5100",
      confirmButtonText: "Aceptar",
      backdrop: false,
      allowOutsideClick: true,
      //position: 'center',
      customClass: { container: "swal-wide", confirmButton: "colorTexto" },
      onClose: () => {
        if (event) event();
      },
    });
  }

  successAsociado(titulo: string, mensaje: string, event?: any, cancelButtonText?: any) {
    return Swal.fire({
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      //text: "<b>" + mensaje + "</b>",
      icon: "success",
      allowOutsideClick: true,
      confirmButtonColor: "#FF5100",
      confirmButtonText: "Agregar",
      showCancelButton: true,
      backdrop: false,
      cancelButtonColor: "#d33",
      cancelButtonText: cancelButtonText ? cancelButtonText : "Siguiente",
      //position: 'center',
      customClass: { container: "swal-wide", confirmButton: "colorTexto" },
      onClose: () => {
        if (event) event();
      },
    });
  }

  AlertCriticoNoEvent(titulo: string, mensaje: string, icono: any) {
    Swal.fire({
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      //text: "<b>" + mensaje + "</b>",
      icon: icono,
      confirmButtonColor: "#FF5100",
      confirmButtonText: "Aceptar",
      backdrop: false,
      allowOutsideClick: true,
      //position: 'center',
      customClass: { container: "swal-wide", confirmButton: "colorTexto" },
    });
  }

  errorAlertInfo(titulo: string, mensaje: string, icono: any, event?: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      confirmButtonColor: "#FF5100",
      confirmButtonText: "Aceptar",
      timer: 5000,
      //timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icono,
      title: mensaje,
    });
  }

  warningAlert(
    titulo: string,
    mensaje: string,
    time?: any,
    icon?: any,
    confirmButtonText?: any,
    cancelButtonText?: any,
    cancelActive = true
  ): Promise<any> {
    return Swal.fire({
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      //text: "<b>" + mensaje + "</b>",
      confirmButtonColor: "#FF5100",
      confirmButtonText: confirmButtonText ? confirmButtonText : "Aceptar",
      icon: icon ? icon : "warning",
      showCancelButton: cancelActive,
      backdrop: false,
      cancelButtonColor: "#d33",
      cancelButtonText: cancelButtonText ? cancelButtonText : "Cancelar",
      timer: time,
    });
  }

  alertaSuceso(
    titulo: string,
    mensaje: string,
    time?: any,
    icon?: any,
    confirmButtonText?: any,
    cancelButtonText?: any,
    cancelActive = true
  ): Promise<any> {
    return Swal.fire({
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      //text: "<b>" + mensaje + "</b>",
      confirmButtonColor: "#FF5100",
      confirmButtonText: confirmButtonText ? confirmButtonText : "Aceptar",
      icon: icon ? icon : "warning",
      backdrop: false,
      timer: time,
    });
  }

  retryRequest(titulo: string, mensaje: string, event?: any) {
    Swal.fire({
      onBeforeOpen: () =>{
        Swal.showLoading()
      },
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      icon: "warning",
      //text: "<b>" + mensaje + "</b>",
      backdrop: false,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: { popup: "servicesAlert" },
      onClose: () => {
        if (event) event();
      },
    });
  }

  transactionError(titulo: string, mensaje: string): Promise<any> {
    return Swal.fire({
      html: "<b>" + mensaje + "</b>",
      title: titulo,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: "#FF5100",
      confirmButtonText: "Aceptar",
      icon: "warning",
      backdrop: false,
      allowEscapeKey: false,
      allowEnterKey: false
    });
  }
}

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observer } from "rxjs";
import { Observable } from "rxjs";
import { Constants } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LogoutAPIService {
  constructor(private http: HttpClient) {}

  MakeRequest(body: any | null): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const options = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      this.http.post(Constants.api, body, options).subscribe(
        (response: any) => {
          let data = null;
          let payload = null;

          if (response.d) {
            let rawData = JSON.parse(response.d).Data;
            data = rawData ? JSON.parse(rawData) : null;
            if (data.PayLoad) {
              payload = data.PayLoad;
            }
          }

          if (data == null || payload == null) {
            observer.error("Ha ocurrido un problema con la conexión al servidor.");
          }
          observer.next(payload);
          observer.complete();
        },
        (error) => {
          observer.error("Ha ocurrido un problema con la conexión al servidor");
        }
      );
    });
  }

  postData(body: any | null): Observable<any> {
    return new Observable((observer: Observer<Array<any>>) => {
      let request = {
        path: body.path,
        data: JSON.stringify(body.data),
      };
      this.MakeRequest(request).subscribe(
        (data: any) => {
          const resp = JSON.parse(data);
          if (resp.ObjectValue) {
            if (resp.ObjectValue[0].IdResult) {
              if (resp.ObjectValue[0].IdResult !== 200) {
                observer.error(resp.ObjectValue[0]);
              }
            }
          }

          observer.next(resp);
          observer.complete();
        },
        (error: any) => {
          observer.error(error);
        }
      );
    });
  }
}

import { SecureSimpleStorage } from 'src/data/disk-data-source/secure-simple-storage';
import { Injectable } from '@angular/core';
import { CrReqRes } from '../utility/crReqRes';
import CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptedCookieStorageService {
  keyIV: any;
  useEncryption: boolean;
  
  constructor(public criReqRes: CrReqRes, private secureStorage: SecureSimpleStorage) {

    const privada = this.forward(8, 'QW5nTG9yZW1JcHN1bWF5Z2luYWdhbWl0bmFtb2RlbG9uZ2luZHVzdHJpeWFuZ3BhZ3ByaXByaW50YXRwYWd0eXR5cG' +
    'VzZXQuQW5nTG9yZW1JcDkwc3VtYW5nbmExMjM0NTY3OGdpbmdyZWd1bGFybmFtb2RlbG9zaW11bGFwYW5vb25nMTUwMHMsbm9vbmdtYXlpc2FuZ2Rpa2lsYWxhbmdtYW5saWx' +
    'pbWJhZ2FuZGt1bXVoYW5nZ2FsbGV5bmd0eXBlYXRnaW51bG9hbmdwYWdrYWthLWF5b3NuaXRvdXBhbmdtYWthZ2F3YW5nbGlicm9uZ21nYXR5cGVzcGVjaW1lbi5OYWxhZ3Bh' +
    'c2Fubml0b2hpbmRpbGFuZ2xpbWFuZ3NpZ2xvLGt1bmRpbmFsYWdwYXNhbmRpbm5pdG9hbmdwYWdsYWdhbmFwOTBhYmNkZWZuZ2VsZWN0cm9uaWN0eXBlc2V0dGluZ2F0bmFuY' +
    'XRpbGluZ3BhcmVoYXMuU3VtaWthdGl0b25vb25nMTk2MHNrYXNhYmF5bmdwYWdsYWJhc25nTGV0cmFzZXRzaGVldHNuYW1heXJvb25nbWdhdGFsYXRhbmdMb3JlbUlwc3VtLG' +
    'F0a2FtYWthaWxhbmxhbmdzYW1nYWRlc2t0b3BwdWJsaXNoaW5nc29mdHdhcmV0dWxhZG5nQWxkdXNQYWdlbWFrZXJnaW5hbWl0YW5nbWdoaWprbG1uZ2FiZXJzeW9ubmdMb3J' +
    'lbUlwc3VtLkFuZ0xvcmVtSXBzdW1heWdpbmFnYW1pdG5hbW9kZWxvbmdpbmR1c3RyaXlhbmdwYWdwcmlwcmludGF0cGFndHl0eXBlc2V0LkFuZ0xvcmVtSXA5MHN1bWFuZ25h' +
    'Z2luZ3JlZ3VsYXJuYW1vZGVsb3NpbXVsYXBhbm9vbmcxNTAwcyxub29uZ21heWlzYW5nZGlraWxhbGFuZ21hbmxpbGltYmFnYW5ka3VtdWhhbmdnYWxsb3BxcnN0dXZleW5ndH' +
    'lwZWF0Z2ludWxvYW5ncGFna2FrYS1heW9zbml0b3VwYW5nbWFrYWdhd2FuZ2xpYnJvbmdtZ2F0eXBlc3BlY2ltZW4uTmFsYWdwYXNhbm5pdG9oaW5kaWxhbmdsaW1hbmdzaWds' +
    'byxrdW5kaW5hbGFncGFzYW5kaW5uaXRvYW5ncGFnbGFnYW5hcG5nZWxlY3Ryb25pY3R5cGVzZXR0aW5nYXRuYW5hdGlsaW5ncGFyZWhhcy5TdW1pa2F0aXRvbm9vbmcxOTYwc2' +
    'thc2FiYXluZ3BhZ2xhYmFzbmdMZXRyYXNldHNoZWV0c25hbWF5cm9vbmdtZ2F0YWxhdGFuZ0xvcmVtSXBzdW0sYXRrYW1ha2FpbGFubGFuZ3NhbWdhZGVza3RvcHB1Ymxpc2hp' +
    'bmdzb2Z0d2FyZXR1bGFkbmdBbGR1c1BhZ2VtYWtlcmdpbmFtaXRhbmdtZ2FiZXJzeW9ubmdMb3JlbUlwc3VtLg==');

    const publica = this.forward(4, 'QW5nTG9yZW1JcHN1bWF5Z2luYWdhbWl0bmFtb2RlbG9uZ2luZHVzdHJpeWFuZ3BhZ3ByaXByaW50YXRwYWd0eXR5cGVz' +
        'ZXQuQW5nTG9yZW1JcDkwc3VtYW5nbmEwMTIxZ2luZ3JlZ3VsYXJuYW1vZGVsb3NpbXVsYXBhbm9vbmcxNTAwcyxub29uZ21heWlzYW5nZGlraWxhbGFuZ21hbmxpbGltYmFnYW5' +
        'ka3VtdWhhbmdnYWxsZXluZ3R5cGVhdGdpbnVsb2FuZ3BhZ2tha2EtYXlvc25pdG91cGFuZ21ha2FnYXdhbmdsaWJyb25nbWdhdHlwZXNwZWNpbWVuLk5hbGFncGFzYW5uaXRvaGl' +
        'uZGlsYW5nbGltYW5nc2lnbG8sa3VuZGluYWxhZ3Bhc2FuZGlubml0b2FuZ3BhZ2xhZ2FuYXA0MjMxbmdlbGVjdHJvbmljdHlwZXNldHRpbmdhdG5hbmF0aWxpbmdwYXJlaGFzLlN' +
        '1bWlrYXRpdG9ub29uZzE5NjBza2FzYWJheW5ncGFnbGFiYXNuZ0xldHJhc2V0c2hlZXRzbmFtYXlyb29uZ21nYXRhbGF0YW5nTG9yZW1JcHN1bSxhdGthbWFrYWlsYW5sYW5nc2F' +
        'tZ2FkZXNrdG9wcHVibGlzaGluZ3NvZnR3YXJldHVsYWRuZ0FsZHVzUGFnZW1ha2VyZ2luYW1pdGFuZ200NDEyZ2FiZXJzeW9ubmdMb3JlbUlwc3VtLkFuZ0xvcmVtSXBzdW1heWd' +
        'pbmFnYW1pdG5hbW9kZWxvbmdpbmR1c3RyaXlhbmdwYWdwcmlwcmludGF0cGFndHl0eXBlc2V0LkFuZ0xvcmVtSXA5MHN1bWFuZ25hZ2luZ3JlZ3VsYXJuYW1vZGVsb3NpbXVsYXB' +
        'hbm9vbmcxNTAwcyxub29uZ21heWlzYW5nZGlraWxhbGFuZ21hbmxpbGltYmFnYW5ka3VtdWhhbmdnYWxsMzQxMmV5bmd0eXBlYXRnaW51bG9hbmdwYWdrYWthLWF5b3NuaXRvdXB' +
        'hbmdtYWthZ2F3YW5nbGlicm9uZ21nYXR5cGVzcGVjaW1lbi5OYWxhZ3Bhc2Fubml0b2hpbmRpbGFuZ2xpbWFuZ3NpZ2xvLGt1bmRpbmFsYWdwYXNhbmRpbm5pdG9hbmdwYWdsYWd' +
        'hbmFwbmdlbGVjdHJvbmljdHlwZXNldHRpbmdhdG5hbmF0aWxpbmdwYXJlaGFzLlN1bWlrYXRpdG9ub29uZzE5NjBza2FzYWJheW5ncGFnbGFiYXNuZ0xldHJhc2V0c2hlZXRzbmF' +
        'tYXlyb29uZ21nYXRhbGF0YW5nTG9yZW1JcHN1bSxhdGthbWFrYWlsYW5sYW5nc2FtZ2FkZXNrdG9wcHVibGlzaGluZ3NvZnR3YXJldHVsYWRuZ0FsZHVzUGFnZW1ha2VyZ2luYW1' +
        'pdGFuZ21nYWJlcnN5b25uZ0xvcmVtSXBzdW0u');

    this.keyIV = this.criReqRes.getGateAndIV(privada, publica);
    this.useEncryption = environment.production;
  }
  globalVariable: any;
  save(
    key: string,
    value: string,
    expires: Date,
    domain?: string,
    path?: string
    ): void {
    try {
        const encryptedKey =
            this.criReqRes.forward(
                this.criReqRes.CIPHERS.AES_256,
                this.keyIV.gate,
                this.keyIV.iv,
                key
            );

        const encryptedValue = this.criReqRes.forward(
            this.criReqRes.CIPHERS.AES_256,
            this.keyIV.gate,
            this.keyIV.iv,
            value
        );

        let encodeValue = encodeURIComponent(encryptedValue);
        let encodeKey = encodeURIComponent(encryptedKey);

        // Construir la cadena de opciones de la cookie
        let cookieOptions = `expires=${expires.toUTCString()}; `;
        if (domain) {
            cookieOptions += `domain=${domain}; `;
        }
        if (path) {
            cookieOptions += `path=${path}; `;
        }
        
        if (this.useEncryption) {
            // Establecer la cookie encriptada con opciones adicionales
            document.cookie = `${encodeKey}=${encodeValue};${cookieOptions}`;
        } else {
            // Establecer la cookie sin encriptar con opciones adicionales
            document.cookie = `${key}=${value};${cookieOptions}`;
        }
    } catch (error) {
        console.error(error);
    }
  }
  
  get(key: string): string | null {
    const encryptedKey = this.criReqRes.forward(
        this.criReqRes.CIPHERS.AES_256,
        this.keyIV.gate,
        this.keyIV.iv,
        key
    );

    let encodeKey = encodeURIComponent(encryptedKey);

    if (this.useEncryption) {
        // Aquí se obtiene la cookie encriptada por su clave
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const parts = cookie.split('=');
            const cookieKey = parts.shift();
            const cookieValue = parts.join('=');
            
            if (cookieKey.trim() === encodeKey) {
                const decodeValue = decodeURIComponent(cookieValue);

                const decryptedValue = this.criReqRes.rewind(
                    this.criReqRes.CIPHERS.AES_256,
                    this.keyIV.gate,
                    this.keyIV.iv,
                    decodeValue
                );
                
                return decryptedValue;
            }
        }
    } else {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const [cookieKey, cookieValue] = cookie.split('=');
            if (cookieKey.trim() === key) {
                return cookieValue;
            }
        }
    }

    return null;
  }

  remove(key: string, domain: string): void {
    const encryptedKey = this.criReqRes.forward(
        this.criReqRes.CIPHERS.AES_256,
        this.keyIV.gate,
        this.keyIV.iv,
        key
    );

    let encodeKey = encodeURIComponent(encryptedKey);

    if (this.useEncryption) {
        // Eliminar la cookie encriptada
        document.cookie = `${encodeKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    } else {
        // Eliminar la cookie sin encriptar
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    }
  }

  forward(long: number, keyBase64: string): string {
    const words = CryptoJS.enc.Base64.parse(keyBase64);
    const key: string = CryptoJS.enc.Utf8.stringify(words);
    const longitud: number = key.length;
    const letra: number = 97;
    let letraBuscar: string = String.fromCharCode(letra);
    let contado: number = 0;
    let corridas: number = 0;
    let concatenado: string = '';
    for (let i = 0; i < longitud; i++) {
        if (letraBuscar && key.charAt(i) === letraBuscar) {
            contado++;
            if (contado === 10) {
                corridas++;
                concatenado += key.substr(i + 1, long);
                const numeroLetras = 10;
                i = i + numeroLetras;
                contado = 0;
                if (corridas === 1) {
                    letraBuscar = String.fromCharCode(112);
                } else if (corridas === 2) {
                    letraBuscar = String.fromCharCode(109);
                } else if (corridas === 3) {
                    letraBuscar = String.fromCharCode(108);
                } else {
                    letraBuscar = null;
                }
            }
        }
    }
    return concatenado;
  }
}

import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { Observable, Observer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  public loaderToShow: any;
  public loaders: Array<number> = [];

  constructor(private loadingController: LoadingController) {
    //this.loaders = 0;
  }

  showLoader(Message?: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.loaders.push(this.loaders.length + 1);
      if (this.loaders.length > 1) {
        observer.next(true);
        observer.complete();
      }

      this.loaderToShow = this.loadingController
        .create({
          message: Message ? Message : "Cargando información...",
          cssClass: "showloading",
          spinner: "bubbles",
          // duration: 8000
        })
        .then(
          (res) => {
            res.present().then(
              () => {
                observer.next(true);
                observer.complete();
              },
              (error) => {
                observer.error(error);
              }
            );
            res.onDidDismiss().then((dis) => {});
          },
          (error) => {
            console.error(error);
            observer.error(error);
          }
        );
    });
  }

  hideLoader(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      if (this.loaderToShow && this.loaders.length === 1) {
        this.loadingController
          .dismiss()
          .then(() => {
            observer.next(true);
            observer.complete();
          })
          .catch((error) => {});
      }

      this.loaders.pop();

      //setTimeout(() => {
      this.loadingController
        .dismiss()
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch((error) => {});
      //}, 100);
    });
  }
}

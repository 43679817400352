import { NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from "@angular/router";
import { AuthGuardService as AuthGuard } from "../domain/utility/auth-guard.service";
import { AuthGuardRedirect as AuthRedirect } from "src/domain/utility/auth-redirect.service";
import { AuthGuardRedirect as AuthRedirectAfiliados } from "src/domain/utility/auth-redirect-afiliados.service";
import { AuthGuardRedirect as AuthRedirectDesign } from "src/domain/utility/auth-redirect-design.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "historico",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("../presentation/views/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "login-exc",
    loadChildren: () => import("../presentation/views/login-exc/login-exc.module").then((m) => m.LoginExcModule),
  },
  {
    path: "historico",
    loadChildren: () => import("../presentation/views/my-services/my-services.module").then((m) => m.MyServicesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "historico/:status",
    loadChildren: () => import("../presentation/views/my-services/my-services.module").then((m) => m.MyServicesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "perfil",
    loadChildren: () => import("../presentation/views/home/home.module").then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "guide-assignment",
    loadChildren: () => import("../presentation/views/guide-assignment/guide-assignment.module").then((m) => m.guideAssignmentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "direcciones",
    loadChildren: () => import("../presentation/views/direcciones/direcciones.module").then((m) => m.DireccionesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/centro-canje",
    loadChildren: () => import("../presentation/views/centro-canje/centro-canje.module").then((m) => m.CentroCanjeModule),
    canActivate: [AuthGuard],
  },
  {
    path: "perfil/editar-perfil",
    loadChildren: () => import("../presentation/views/editar-pefil/editar-pefil.module").then((m) => m.EditarPefilPageModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "facturacion",
    loadChildren: () => import("../presentation/views/facturacion/facturacion.module").then((m) => m.FacturacionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "pagos",
    loadChildren: () => import("../presentation/views/pagos/pagos.module").then((m) => m.PagosPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "modificar-contrasenia",
    loadChildren: () =>
      import("../presentation/views/modificar-contrasenia/modificar-contrasenia.module").then((m) => m.ModificarContraseniaPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "crear-cuenta",
    loadChildren: () => import("../presentation/views/crear-cuenta/crear-cuenta.module").then((m) => m.CrearCuentaPageModule),
  },
  {
    path: "forgotpassword",
    loadChildren: () =>
      import("../presentation/views/restablecer-contrasenia/restablecer-contrasenia.module").then(
        (m) => m.RestablecerContraseniaPageModule
      ),
  },
  {
    path: "facturacion/crear-facturacion",
    loadChildren: () =>
      import("../presentation/views/facturacion/crear-facturacion/crear-facturacion.module").then((m) => m.CrearFacturacionPageModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "facturacion/editar-facturacion/:id",
    loadChildren: () =>
      import("../presentation/views/facturacion/editar-facturacion/editar-facturacion.module").then((m) => m.EditarFacturacionPageModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "direcciones/crear-direccion",
    loadChildren: () =>
      import("../presentation/views/direcciones/crear-direccion/crear-direccion.module").then((m) => m.CrearDireccionPageModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "direcciones/editar-direccion/:id",
    loadChildren: () =>
      import("../presentation/views/direcciones/editar-direccion/editar-direccion.module").then((m) => m.EditarDireccionPageModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "pagos/crear-metodo-pago",
    loadChildren: () =>
      import("../presentation/views/pagos/crear-metodo-pago/crear-metodo-pago.module").then((m) => m.CrearMetodoPagoPageModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "pagos/lista-pagos",
    loadChildren: () => import("../presentation/views/pagos/lista-pagos/lista-pagos.module").then((m) => m.ListaPagosPageModule),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "cotizador",
    loadChildren: () => import("../presentation/views/cotizador/cotizador.module").then((m) => m.CotizadorPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "rastreo/:guia",
    loadChildren: () => import("../presentation/views/rastreo/rastreo.module").then((m) => m.RastreoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "rastreo",
    loadChildren: () => import("../presentation/views/rastreo/rastreo.module").then((m) => m.RastreoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express",
    loadChildren: () => import("../presentation/views/tabs/tabs.module").then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/crear-guia",
    loadChildren: () => import("../presentation/views/cotizador/cotizador.module").then((m) => m.CotizadorPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/crear-guias",
    loadChildren: () =>
      import("../presentation/views/cotizador-corporativo/cotizador-corporativo.module").then((m) => m.CotizadorCorporativoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/detalles",
    loadChildren: () => import("../presentation/views/cotizador/envio/envio.module").then((m) => m.EnvioPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/detalles-corp",
    loadChildren: () =>
      import("../presentation/views/cotizador-corporativo/envio-corporativo/envio-corporativo.module").then(
        (m) => m.EnvioCorporativoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "express/servicios",
    loadChildren: () => import("../presentation/views/cotizador/servicios/servicios.module").then((m) => m.ServiciosPageModule),

    canActivate: [AuthGuard],
  },
  {
    path: "express/servicios-corp",
    loadChildren: () =>
      import("../presentation/views/cotizador-corporativo/servicios-corporativo/servicios-corporativo.module").then(
        (m) => m.ServiciosCorporativoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "express/generar-guia",
    loadChildren: () =>
      import("../presentation/views/cotizador/generacion-guia/generacion-guia.module").then((m) => m.GeneracionGuiaPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/pago",
    loadChildren: () =>
      import("../presentation/views/cotizador/pagos-servicio/pagos-servicio.module").then((m) => m.PagosServicioPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/pago-corp",
    loadChildren: () =>
      import("../presentation/views/cotizador-corporativo/pago-servicio-corporativo/pago-servicio-corporativo-routing.module").then(
        (m) => m.PagoServicioCorporativoPageRoutingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "pago-facturacion",
    loadChildren: () =>
      import("../presentation/views/cotizador/pago-facturacion/pago-facturacion.module").then((m) => m.PagoFacturacionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "password-reset/:token",
    loadChildren: () => import("../presentation/views/password-reset/password-reset.module").then((m) => m.PasswordResetPageModule),
  },
  {
    path: "account-confirmation/:token",
    loadChildren: () =>
      import("../presentation/views/account-confirmation/account-confirmation.module").then((m) => m.AccountConfirmationPageModule),
  },
  {
    path: "restablecer-contrasenia",
    loadChildren: () =>
      import("../presentation/views/restablecer-contrasenia/restablecer-contrasenia.module").then(
        (m) => m.RestablecerContraseniaPageModule
      ),
  },
  {
    path: "firstBilling",
    loadChildren: () =>
      import("../presentation/views/first-billing-address/first-billing-address.module").then((m) => m.FirstBillingAddressPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "solicitar-recoleccion",
    loadChildren: () => import("../presentation/views/schedule-pickup/schedule-pickup.module").then((m) => m.SchedulePickupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "solicitar-recoleccion/:guia",
    loadChildren: () => import("../presentation/views/schedule-pickup/schedule-pickup.module").then((m) => m.SchedulePickupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "mis-cuentas-bancarias",
    loadChildren: () =>
      import("../presentation/views/mis-cuentas-bancarias/mis-cuentas-bancarias.module").then((m) => m.MisCuentasBancariasPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "mis-cuentas-bancarias/crear",
    loadChildren: () =>
      import("../presentation/views/mis-cuentas-bancarias/editar-cuenta-bancaria/editar-cuenta-bancaria.module").then(
        (m) => m.EditarCuentaBancariaPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mis-cuentas-bancarias/editar/:id",
    loadChildren: () =>
      import("../presentation/views/mis-cuentas-bancarias/editar-cuenta-bancaria/editar-cuenta-bancaria.module").then(
        (m) => m.EditarCuentaBancariaPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "pago-recoleccion",
    loadChildren: () => import("../presentation/views/pago-recoleccion/pago-recoleccion.module").then((m) => m.PagoRecoleccionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "facturacion-recoleccion",
    loadChildren: () =>
      import("../presentation/views/facturacion-recoleccion/facturacion-recoleccion.module").then(
        (m) => m.FacturacionRecoleccionPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "prepare-pickup",
    loadChildren: () => import("../presentation/views/prepare-pickup/prepare-pickup.module").then((m) => m.PreparePickupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "prepare-pickup/:guia",
    loadChildren: () => import("../presentation/views/prepare-pickup/prepare-pickup.module").then((m) => m.PreparePickupPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "resumen-recoleccion",
    loadChildren: () =>
      import("../presentation/views/resumen-recoleccion/resumen-recoleccion.module").then((m) => m.ResumenRecoleccionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "express/editar-pago/:guia",
    loadChildren: () => import("../presentation/views/cotizador/editar-pago/editar-pago.module").then((m) => m.EditarPagoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "cartera-clientes",
    loadChildren: () => import("../presentation/views/cartera-clientes/cartera-clientes.module").then((m) => m.CarteraClientesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "cartera-clientes/:operation",
    loadChildren: () =>
      import("../presentation/views/cartera-clientes/editar-cartera-clientes/editar-cartera-clientes.module").then(
        (m) => m.EditarCarteraClientesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "cierres",
    loadChildren: () => import("../presentation/views/cierres/cierres.module").then((m) => m.CierresPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "reportes",
    loadChildren: () =>
      import("../presentation/views/cierres/reporte-cierres/reporte-cierres.module").then((m) => m.ReporteCierresPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "cartera-corporativos",
    loadChildren: () =>
      import("../presentation/views/cartera-clientes-corporativos/cartera-clientes-corporativos.module").then(
        (m) => m.CarteraClientesCorporativosPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "cartera-corporativos/:operation",
    loadChildren: () =>
      import("../presentation/views/cartera-clientes-corporativos/editar-cartera-corporativos/editar-cartera-corporativos.module").then(
        (m) => m.EditarCarteraCorporativosPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "guias-rapidas",
    loadChildren: () => import("../presentation/views/guias-rapidas/guias-rapidas.module").then((m) => m.GuiasRapidasPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "login-corporate",
    loadChildren: () => import("../presentation/views/login-corporate/login-corporate.module").then((m) => m.LoginCorporatePageModule),
  },
  {
    path: "servicios",
    loadChildren: () => import("../presentation/views/services/services.module").then((m) => m.ServicesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "servicios/traslado/registro",
    loadChildren: () =>
      import("../presentation/views/services/service-transfer/service-transfer-registry/service-transfer-registry.module").then(
        (m) => m.ServiceTransferRegistryPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "servicios/traslado/finalizacion",
    loadChildren: () =>
      import("../presentation/views/services/service-transfer/service-transfer-checkout/service-transfer-checkout.module").then(
        (m) => m.ServiceTransferCheckoutPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "servicios/traslado/resumen",
    loadChildren: () =>
      import("../presentation/views/services/service-transfer/service-transfer-summary/service-transfer-summary.module").then(
        (m) => m.ServiceTransferSummaryPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "servicios/:type/registro",
    loadChildren: () =>
      import("../presentation/views/services/service-guide-registry/service-guide-registry.module").then(
        (m) => m.ServiceGuideRegistryPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "servicios/:type/resumen",
    loadChildren: () =>
      import("../presentation/views/services/service-summary/service-summary.module").then((m) => m.ServiceSummaryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "servicios/:type/finalizacion",
    loadChildren: () =>
      import("../presentation/views/services/service-checkout/service-checkout.module").then((m) => m.ServiceCheckoutPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "servicios/:type/incidencia",
    loadChildren: () =>
      import("../presentation/views/services/service-complain/service-complain.module").then((m) => m.ServiceComplainPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cierre-operador',
    loadChildren: () => import('../presentation/views/cierre-operador/cierre-operador.module').then( m => m.CierreOperadorPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "reporte-operador",
    loadChildren: () =>
      import("../presentation/views/cierre-operador/reporte-operador/reporte-operador.module").then((m) => m.ReporteOperadorPageModule),
      canActivate: [AuthGuard],
  },
  {    
    path: 'generar-manifiestos',
    loadChildren: () => import('../presentation/views/generar-manifiestos/generar-manifiestos.module').then( m => m.GenerarManifiestosPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'resumen-manifiestos',
    loadChildren: () => import('../presentation/views/resumen-manifiestos/resumen-manifiestos.module').then( m => m.ResumenManifiestosPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'lectura-manifiestos/:manifest',
    loadChildren: () => import('../presentation/views/lectura-manifiestos/lectura-manifiestos.module').then( m => m.LecturaManifiestosPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edicion-manifiestos/:manifestNumber',
    loadChildren: () => import('../presentation/views/edicion-manifiestos/edicion-manifiestos.module').then( m => m.EdicionManifiestosPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "login-internal",
    pathMatch: "full",
    loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
    canActivate: [AuthRedirect]
  },
  {
    path: "login-affiliate",
    pathMatch: "full",
    loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
    canActivate: [AuthRedirectAfiliados]
  },
  {
    path: "design/individual",
    children:[
      {
        path:'nuestros-servicios',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/nuestros-servicios'
        }
      },
      {
        path:'crear-guia/estandar',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/crear-guia/estandar'
        }
      },
      {
        path:'crear-guia/cod',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/crear-guia/cod'
        }
      },
      {
        path:'recolecciones',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/recolecciones'
        }
      },
      {
        path:'recoleccion/manual',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/recoleccion/manual'
        }
      },
      {
        path:'activar-consultar-membresias-suscripciones',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/activar-consultar-membresias-suscripciones'
        }
      },
      {
        path:'faq',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/faq'
        }
      },
      {
        path:'checkout',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/checkout'
        }
      },
      {
        path:'perfil',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/perfil'
        }
      },
      {
        path:'facturacion',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/facturacion'
        }
      },
      {
        path:'mis-cuentas-bancarias',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/mis-cuentas-bancarias'
        }
      },
      {
        path:'direcciones',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/direcciones'
        }
      },
      {
        path:'tarjetas',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/individual/tarjetas'
        }
      }
    ]
  },
  {
    path: "design/dashboard",
    canActivate:[AuthRedirectDesign],
    loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
    data:{
      url:'/dashboard'
    }
  },
  {
    path: "design/mis-envios",
    canActivate:[AuthRedirectDesign],
    loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
    data:{
      url:'/mis-envios'
    }
  },
  {
    path: "design/mis-cod",
    canActivate:[AuthRedirectDesign],
    loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
    data:{
      url:'/mis-cod'
    }
  },
  {
    path: "design/telemercadeo",
    children:[
      {
        path:'crear-cuenta',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/telemercadeo/crear-cuenta'
        }
      },
      {
        path:'detalle-membresias',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/telemercadeo/detalle-membresias'
        }
      },
      {
        path:'reporte-clientes-cartera',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/telemercadeo/reporte-clientes-cartera'
        }
      },
      {
        path:'compra-membresia',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/telemercadeo/compra-membresia'
        }
      },
      {
        path:'administracion-usuarios',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/telemercadeo/administracion-usuarios'
        }
      },
    ]
  },
  {
    path: "design/affiliate",
    children: [
      {
        path:'redeem-center',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/affiliate/redeem-center'
        }
      },
      {
        path:'dashboard-affiliate',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/affiliate/dashboard-affiliate'
        }
      }
    ]
  },
  // Mapeo de rutas de operaciones / Portal Internos
  {
    path: "design/operaciones",
    children: [
      {
        path:'incidencias-sac',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/incidencias-sac'
        }
      },
      {
        path:'entregas/dashboard-incidencias',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/entregas/dashboard-incidencias'
        }
      },
      {
        path:'recolecciones/manual',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/recolecciones/manual'
        }
      },
      {
        path:'recolecciones/monitoreo',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/recolecciones/monitoreo'
        }
      },
      {
        path:'recolecciones/asignacion',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/recolecciones/asignacion'
        }
      },
      {
        path:'recolecciones/operaciones',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/recolecciones/operaciones'
        }
      },
      {
        path:'recolecciones/dashboard-recolecciones',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/recolecciones/dashboard-recolecciones'
        }
      },
      {
        path:'entregas/visitas-fallidas',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/entregas/visitas-fallidas'
        }
      },
      {
        path:'incidencias-operaciones',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/operaciones/incidencias-operaciones'
        }
      }
    ]
  },
  // Mapeo de rutas de control de calidad / Portal Internos
  {
    path: "design/control-calidad",
    children: [
      {
        path:'control-calidad',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/control-calidad/control-calidad'
        }
      },
      {
        path:'reportes/historial-validaciones',
        canActivate:[AuthRedirectDesign],
        loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
        data:{
          url:'/control-calidad/reportes/historial-validaciones'
        }
      },
    ]
  },
  {
    path:'clubForza/:idMembership',
    loadChildren: () => import("../presentation/views/flujos-qr/flujos-qr.module").then((m) => m.FlujosQrPageModule),
  },
  {
    path:'notification/:idCustom',
    loadChildren: () => import("../presentation/views/redirect-portal-new/redirect-portal-new.module").then((m) => m.RedirectPortalNewPageModule),
  },
  {
    path: "**",
    pathMatch: "full",
    loadChildren: () => import("../presentation/views/error404/error404.module").then((m) => m.Error404PageModule),
    canActivate: [AuthGuard],
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
